import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavBarModule } from '../shared/components/nav-bar/nav-bar.module';
import { SideNavBarModule } from '../shared/components/side-nav-bar/side-nav-bar.module';
import { StoreModule } from '@ngrx/store';
import { CountryReducer } from '../store/reducers/country.reducer';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { PaymentOrderComponent } from './pages/payment-order/payment-order.component';
import { FormContainerModule } from '../shared/components/form-container/form-container.module';
import { ImageBreakPointModule } from '../shared/directives/image-break-point/image-break-point.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    UserComponent,
    PaymentOrderComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    MatSidenavModule,
    MatMenuModule,
    NavBarModule,
    SideNavBarModule,
    StoreModule.forRoot({
      Country: CountryReducer,
    }),
    NgxPageScrollCoreModule.forRoot({ duration: 1000,scrollOffset:50}),
    FormContainerModule,
    ImageBreakPointModule,
    MatIconModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDividerModule,
  ]
})
export class UserModule { }
