<div class="product">
  <!-- <div class="out-of-stock" *ngIf="product.out_of_stock">غير متوفر</div> -->
  <a [routerLink]="'product/' + product.id" *ngIf="!fromBlogs">
    <img
      [ngClass]="isRelated ? 'relatedImg' : 'img'"
      [appImageBreakPoint]="api + '/media/image/' + product.main_image_id"
      [alt]="product.title"
    />
  </a>
  <a [routerLink]="'../../home/product/' + product.id" *ngIf="fromBlogs">
    <img
      [ngClass]="isRelated ? 'relatedImg' : 'img'"
      [appImageBreakPoint]="api + '/media/image/' + product.main_image_id"
      [alt]="product.title"
    />
  </a>
  <div class="productInfo">
    <div class="d-flex flex-between align-center">
      <div [ngClass]="isRelated ? 'relatedName' : 'name'" dir="rtl">
        <a [routerLink]="'product/' + product.id">
          <h1>{{ product.title }}</h1>
        </a>
      </div>
      <!-- <div class="discount" *ngIf="product.discount">
        <span>{{ product.discount }}% off</span>
      </div> -->
    </div>
    <div [ngClass]="isRelated ? 'relatedPrice' : 'price'">
      <span class="newPrice" dir="rtl">{{
        product
          | currency
            : topNavService.country
            : product.discount
            : undefined
            : this.topNavService.countriesList
      }}</span>
      <span class="oldPrice" *ngIf="product.discount" dir="rtl">{{
        product
          | currency
            : topNavService.country
            : product.discount
            : 0
            : this.topNavService.countriesList
      }}</span>
    </div>
    <div class="addToBasket">
      <button
        *ngIf="!product.out_of_stock"
        (click)="addToBasket(product)"
        [ngClass]="
          isRelated
            ? 'relatedAddToBasketButton globalCustomButton'
            : 'collectionAddToBasketButton globalCustomButton'
        "
        style="font-weight: 500"
      >
        <span >أضف إلى السلة</span>
      </button>

      <button
      *ngIf="product.out_of_stock"
        [disabled]="product.out_of_stock"
        class="disabled"
        style="font-weight: 500"
      >
      <span >غير متوفر حاليا</span>
      </button>
    </div>
  </div>
</div>
