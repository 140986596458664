<mat-nav-list>
  <a
    mat-list-item
    [routerLink]="['/', 'about-us']"
    [routerLinkActive]="['is-active']"
    ><mat-icon>info</mat-icon>من نحن</a
  >
  <!-- <a
    mat-list-item
    [routerLink]="['/', 'schools']"
    [routerLinkActive]="['is-active']"
    ><mat-icon>info</mat-icon>مدارس</a
  > -->

  <a
    mat-list-item
    [routerLink]="['/', 'how-to-subscription']"
    [routerLinkActive]="['is-active']"
    ><mat-icon>subscriptions</mat-icon>كيف تشترك</a
  >

  <a
    mat-list-item
    [routerLink]="['/', 'shop']"
    [routerLinkActive]="['is-active']"
    class="shop"
  >
    <span class="new">جديد!</span>
    <mat-icon>shopping_cart</mat-icon>
    المتجر
  </a>

  <a
    mat-list-item
    [routerLink]="['/', 'blog']"
    [routerLinkActive]="['is-active']"
    ><mat-icon>article</mat-icon> المدونة</a
  >

  <ng-container *ngIf="authService.user$ | async">
    <a
      mat-list-item
      [routerLink]="['/', 'account']"
      [routerLinkActive]="['is-active']"
      ><mat-icon>account_circle</mat-icon>حسابي</a
    >
    <a mat-list-item (click)="authService.logout()"
      ><mat-icon>logout</mat-icon>تسجيل الخروج</a
    >
  </ng-container>

  <ng-container *ngIf="!(authService.user$ | async)">
    <a
      mat-list-item
      [routerLink]="['/', 'auth', 'signin']"
      [routerLinkActive]="['is-active']"
      ><mat-icon>login</mat-icon>تسجيل دخول</a
    >
  </ng-container>
</mat-nav-list>
