import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasketService } from '../../services/basket.service';
import { environment } from 'src/environments/environment';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';
import { CountriesService } from 'src/app/shared/services/api/countries.service';
import { ConversionService } from 'src/app/shared/services/api/conversion.service';
import { Product } from 'src/app/models/models';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  api = environment.API_URL;
  @Input() product: any;
  @Input() fromBlogs: boolean = false;
  @Input() isRelated: Boolean = false;

  constructor(
    private basketService: BasketService,
    public dialog: MatDialog,
    public topNavService: TopNavService,
    public countriesService: CountriesService,
    private conversionService: ConversionService,
    private elementRef: ElementRef
  ) {
  }

  navigation = false;
  pagination = false;

  addToBasket(product: Product) {
    this.basketService.addItem(product);
    this.conversionService.shopAddToCart(product, this.topNavService.country);
    this.dialog.open(MessageDialogComponent, {});
  }

  ngOnInit(): void {
    // console.log('nnnnnnnnnnnnnnnnnnnnn', this.product);
  }

  // ngAfterViewInit() {
  //   var s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '/assets/js/script.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  // afterScriptAdded() {
  //   const params = {
  //     width: '350px',
  //     height: '420px',
  //   };

  //   if ((typeof window as any['functionFromExternalScript']) === 'function') {
  //     window as any['functionFromExternalScript'];
  //     params;
  //   }
  // }

  isDesktopOnProduct() {
    const mediaQueryList = window.matchMedia(`(min-width: 1200px)`);

    console.log(mediaQueryList.matches); // true or false

    mediaQueryList.addEventListener(
      'change',
      (event) => console.log(event.matches) // true or false
    );

    return mediaQueryList.matches;
  }
}
