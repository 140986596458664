<section class="get-products-section">
  <!-- <div class="globalContainer"> -->

  <div class="" [ngClass]="{ 'get-products-textbox': !relatedProducts.length, 'get-products-textbox-related': relatedProducts.length }">
    <div class="get-product-ic-box">
      <img
        src="../../../../../assets/get-product-ic2.png"
        alt="image"
        loading="lazy"
      />
      <p class="get-products-title" *ngIf="!relatedProducts.length">أي صندوق في أي وقت!</p>
      <p class="get-products-title" *ngIf="relatedProducts.length"> صناديق ذات صلة</p>

      <img
        src="../../../../../assets/get-product-ic1.png"
        alt="image"
        loading="lazy"
      />
    </div>
    <div class="get-products-subtitle-box" *ngIf="!relatedProducts.length">
      <p class="get-products-subtitle" >
        قد تبحث عن الهدية المثالية لأحبابك ,<span [routerLink]="['/shop']"
          >متجر برق</span
        >
        يمكنك من اهداء المشروع الذي تريد
      </p>
    </div>
  </div>

  <section class="s-32"  >
    <div class="products">
      <ng-container *ngIf="loading; else loadingContent">
        <app-product
          *ngFor="let product of homeProducts | filterProduct"
          [product]="product"
          [isRelated]="false"
        ></app-product>
      </ng-container>
      <a [routerLink]="['/shop']" class="card-b">
        <p class="card-title">تصفح</p>
        <p class="card-title">المزيد</p>
      </a>
    </div>
  </section>

  <section class="s-33"  >
    <div class="swiper-full-p">
      <div class="swiper-wrapper">
        
        <div
          *ngFor="let product of homeProducts | filterProduct"
          class="swiper-slide"
        >
          <ng-container *ngIf="loading; else loadingContent">
            <app-product [product]="product" [isRelated]="false"></app-product>
          </ng-container>
        </div>
        <div class="swiper-slide">
          <a [routerLink]="['/shop']" class="card-2">
            <p class="card-title">تصفح</p>
            <p class="card-title">المزيد</p>
          </a>
        </div>
      
      </div>

      <div class="swiper-pagination-full-p"></div>
      <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>  -->
    </div>
  </section>

  <ng-template #loadingContent>
    <div class="skeleton">
      <div class="head">
        <ngx-skeleton height="350px" width="100%"></ngx-skeleton>
      </div>
      <div class="footer">
        <ngx-skeleton
          height="19px"
          width="50%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="19px"
          width="25%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="30px"
          width="100%"
          margin="0 0 8px 0"
          borderRadius="20px"
          backgroundColor="#FAE80B"
        ></ngx-skeleton>
      </div>
    </div>
    <div class="skeleton">
      <div class="head">
        <ngx-skeleton height="350px" width="100%"></ngx-skeleton>
      </div>
      <div class="footer">
        <ngx-skeleton
          height="25px"
          width="50%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="17px"
          width="25%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="30px"
          width="100%"
          margin="0 0 8px 0"
          borderRadius="20px"
          backgroundColor="#FAE80B"
        ></ngx-skeleton>
      </div>
    </div>
    <div class="skeleton">
      <div class="head">
        <ngx-skeleton height="350px" width="100%"></ngx-skeleton>
      </div>
      <div class="footer">
        <ngx-skeleton
          height="25px"
          width="50%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="17px"
          width="25%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="30px"
          width="100%"
          margin="0 0 8px 0"
          borderRadius="20px"
          backgroundColor="#FAE80B"
        ></ngx-skeleton>
      </div>
    </div>
    <div class="skeleton">
      <div class="head">
        <ngx-skeleton height="350px" width="100%"></ngx-skeleton>
      </div>
      <div class="footer">
        <ngx-skeleton
          height="25px"
          width="50%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="17px"
          width="25%"
          margin="0 0 8px 0"
          borderRadius="20px"
        ></ngx-skeleton>
        <ngx-skeleton
          height="30px"
          width="100%"
          margin="0 0 8px 0"
          borderRadius="20px"
          backgroundColor="#FAE80B"
        ></ngx-skeleton>
      </div>
    </div>
  </ng-template>
  <!-- </div> -->
</section>
