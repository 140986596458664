// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  title: 'Barq - برق',
  production: false,
  faker: true,
  GATEWAY_URL: 'https://mepspay.gateway.mastercard.com',
  GATEWAY_API_VERSION: 61,
  ANALYTICS: false,
  GATEWAY_USERNAME: 'TEST9800082200',

  API_URL: '/api', //'https://barq.dev:3000'
  DOMAIN: 'https://barq.loc.codeobia.com',
  recaptcha_site_key:'6LcYNmAqAAAAACxjZo6tqA4d742vN8zWbpZIXcZe',

  FACEBOOK_ID: '236981954459016',
  GOOGLE_ID: '977533503233-5fe8g0ufusivuotprtqpo23g5uqvndo4.apps.googleusercontent.com',
  APPLE_ID: 'com.barqboxes.website',
  APPLE_REDIRECT_URL: 'https://barq.loc.codeobia.com/auth/signin',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
