import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ProductsService } from '../../shop/services/product.service';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';
import Swiper from 'swiper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-products',
  templateUrl: './get-products.component.html',
  styleUrls: ['./get-products.component.scss'],
})
export class GetProductsComponent implements OnInit {
  loading: boolean = false;
  products: any = [];
  filteredProducts: any = [];
  homeProducts: any = [];
  @Input() relatedProducts: any[] = [];
  

  constructor(
    private productService: ProductsService,
    public topNavService: TopNavService,
    private elementRef: ElementRef,
    private router: Router
  ) {}

  async ngOnInit() {
    if(!this.relatedProducts.length) {
      this.products = await this.productService.getProducts().toPromise();
      this.products = this.products.filter((product: any) => product.showInStore == true);
      this.homeProducts = this.products.filter((product, idx) => idx <= 2);
    } else {
      this.homeProducts = this.relatedProducts.filter((product, idx) => idx <= 2);;
    }

    console.log(this.homeProducts);

    this.loading = true;
  }

  ngAfterViewInit() {
    //script

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/js/script-products-of-barq-store.js';

    s.async = true;
   
    this.elementRef.nativeElement.appendChild(s);
  }


  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };

    if ((typeof window as any['functionFromExternalScript']) === 'function') {
      window as any['functionFromExternalScript'];
      params;
    }
  }
}
