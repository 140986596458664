import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { merge, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TopNavService } from './shared/components/nav-bar/top-nav.service';
import { AuthService } from './shared/services/api/auth.service';
import { NavBarService } from './shared/services/nav-bar/nav-bar.service';
import { PromotionsService } from './shared/services/api/promotions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(MatDrawer) menu: MatDrawer;
  title = 'Barq Box';
  subscriptions: Array<Subscription> = [];
  constructor(
    public topNavService: TopNavService,
    private authService: AuthService,
    public navBarService: NavBarService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
    private promotionsService: PromotionsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && environment.ANALYTICS) {
      this.gtmService.addGtmToDom();
    }

    console.log('Auth Status:', this.authService.isUserLogin);


    setTimeout(() => {
      const promotion_code = this.activatedRoute.snapshot.queryParams.p;

      if (promotion_code) {

        this.promotionsService.getByCode(promotion_code).subscribe((p) => {
          if (p.status_code != 200) {
            this.promotionsService.upsertPromotionCode('');
          } else {
            this.promotionsService.upsertPromotionCode(promotion_code);
          }
          if (p.status_code != 404) {
            this.promotionsService.upsertTruckingCode(p.promotion.id);
          }
        });
      }
    }, 0);

    this.authService.patchAuthStateThereAreAToken();
    const sub = merge(
      this.topNavService.menuSwitch,
      this.navBarService.menuSwitch,
      this.router.events.pipe(
        filter((e) => e instanceof NavigationStart),
        map(() => false)
      )
    ).subscribe((value: boolean) => {
      if (this.menu && this.menu?.close)
        value ? this.menu?.open() : this.menu?.close();
      if (isPlatformBrowser(this.platformId)) {
        window.scroll({ top: 0, left: 0 });
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
